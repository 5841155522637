/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Heading, jsx, AspectRatio, Button, Flex } from 'theme-ui';
import BlockContent from '@sanity/block-content-to-react';
import { SEO } from '../../utils';
import { ReflexLink } from '../../components/header/navbar/reflex-link';
import SermonButton from '../../components/SermonButton';

import ContentContainer from '../../components/content-container';

export const Page = ({ data: { page, sermonSeries }, pageContext }) => {
  const ternaryObjectStyles = {
    objectPosition: page?.mainImage?.hotspot
      ? `${page.mainImage.hotspot.x * 100}% ${page.mainImage.hotspot.y * 100}%`
      : 'center center',
  };

  return (
    <>
      <SEO title={page.title} />
      <Box>
        {page?.mainImage?.asset?.fluid ? (
          <>
            <Img
              fluid={page.mainImage.asset.fluid}
              sx={{
                maxHeight: ['600px'],
              }}
              imgStyle={ternaryObjectStyles}
            />
          </>
        ) : (
          <AspectRatio
            ratio={20 / 2}
            sx={{
              p: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'background',
              bg: 'primary',
            }}
          ></AspectRatio>
        )}
        <ContentContainer>
          <Heading
            as="h1"
            sx={{ py: 3, fontFamily: 'fancyFont', fontSize: [5, '3.3rem'] }}
          >
            {page.title}
          </Heading>
          <BlockContent blocks={page._rawBody} />
          <SermonButton to="/sermons" text="Full archive" />

          <Flex sx={{ py: 4, flexWrap: 'wrap' }}>
            {sermonSeries.nodes.map(series => (
              <Link
                key={series.id}
                to={`/sermon-series/${series.slug.current}`}
                sx={{ px: [0, 0, 1], py: 1, flex: '0 1 350px' }}
              >
                <Img fluid={series.image.asset.fluid} />
              </Link>
            ))}
          </Flex>
        </ContentContainer>
      </Box>
    </>
  );
};

export default Page;

export const query = graphql`
  query {
    page: sanityPage(slug: { current: { eq: "sermon-series" } }) {
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sermonSeries: allSanitySeries(sort: { order: ASC, fields: _createdAt }) {
      nodes {
        title
        id
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _createdAt
      }
    }
  }
`;
