/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { Button, jsx } from 'theme-ui';
import { ReflexLink } from './header/navbar/reflex-link';

export default function SermonButton({ to, text, ...props }) {
  return (
    <Button
      as={ReflexLink}
      to={to}
      sx={{
        mx: 1,
        my: [1, null, 0],
        px: 1,
        py: 0,
        '&[aria-current], &.current': {
          bg: 'secondary',
          pointerEvents: 'none',
        },
        '&[disabled]': {
          pointerEvents: 'none',
          color: 'muted',
        },
      }}
    >
      {text}
    </Button>
  );
}
